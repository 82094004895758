import axios from 'axios';
import etcUrls from '@/core/services/constants/EtcUrls';

const etcService = {
    getCompanyInformation(success, error){
        axios.get(etcUrls.getCompanyInformation)
            .then(responseData => {
                success(responseData.data.data)
            })
            .catch( errorResponse => error(errorResponse.response.data.errors))
    },
    getCompanyWebsiteContent(success, error){
        axios.get(etcUrls.getCompanyWebsiteContent)
            .then(responseData => {
                success(responseData.data.data)
            })
            .catch( errorResponse => error(errorResponse.response.data.errors))
    },
    getAllStations(success, error){
        axios.get(etcUrls.getStationList)
            .then(responseData => {
                success(responseData.data.data)
            })
            .catch( errorResponse => error(errorResponse.response.data.errors))
    },
    getTopRoutes(noOfRoutes,success, error){
        axios.get(etcUrls.getTopRouteList+noOfRoutes)
            .then(responseData => {
                success(responseData.data.data)
            })
            .catch( errorResponse => error(errorResponse.response.data.errors))
    },
    getOperators(success, error){
        axios.get(etcUrls.getOperators)
            .then(responseData => {
                success(responseData.data.data)
            })
            .catch( errorResponse => error(errorResponse.response.data.errors))
    },
    getCompanySummaryInfo(success, error){
        axios.get(etcUrls.getCompanySummary)
            .then(responseData => {
                success(responseData.data)
            })
            .catch( errorResponse => error(errorResponse.response.data.errors))
    }
};

export default etcService;





