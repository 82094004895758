<template>
  <div id="kt_footer" class="footer py-3 d-flex flex-lg-column">
    <!--begin::Container-->
    <div
      class="d-flex flex-column flex-md-row align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <!--begin::Copyright-->
      <div class="order-2 order-md-1">
        <span class="font-weight-bold mr-2">©2024</span>{{getCompanyInfo ? getCompanyInfo.name : '' }}. All rights reserved.
      </div>
      <!--end::Copyright-->
      <!--begin::Nav-->
      <div class="nav nav-light order-1 order-md-2">
        <router-link :to="'/terms-of-use'">
          <a class="nav-link px-3 text-white text-hover-dark">Terms of Use</a>
        </router-link>
        <span class="nav-link px-0 text-white">|</span>
        <router-link :to="'/privacy-policy'">
          <a class="nav-link px-3 text-white text-hover-dark">Privacy Policy</a>
        </router-link>
        <span class="nav-link px-0 text-white">|</span>
        <a class="nav-link px-3 text-white text-hover-dark" href="https://www.facebook.com/chokrojanbd" target="_blank">Follow us on<i class="fab fa-facebook text-white pl-1"></i></a>
      </div>
      <!--end::Nav-->
      <div class="order-2 order-md-3">
        Developed with <i class="fas fa-heart text-danger fa-beat"></i> by <a class="text-white text-hover-dark" title="Chokrojan" href="https://chokrojan.com/" target="_blank">Chokrojan</a>
      </div>
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig","getCompanyInfo"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    }
  }
};
</script>
<style scoped>
/* FONT AWESOME GENERIC BEAT */
.fa-beat {
  animation:fa-beat 5s ease infinite;
}
@keyframes fa-beat {
  0% {
    transform:scale(1);
  }
  5% {
    transform:scale(1.25);
  }
  20% {
    transform:scale(1);
  }
  30% {
    transform:scale(1);
  }
  35% {
    transform:scale(1.25);
  }
  50% {
    transform:scale(1);
  }
  55% {
    transform:scale(1.25);
  }
  70% {
    transform:scale(1);
  }
}
</style>