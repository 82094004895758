<template>
    <div class="d-flex flex-column flex-root" :class="{'page-loading':pageLoading}" id="app" data-app>
      <!-- begin:: Header Mobile -->
      <KTHeaderMobile></KTHeaderMobile>
      <!-- end:: Header Mobile -->

      <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo" :spinner-class="spinnerClass"></Loader>

      <!-- begin::Body -->
      <div class="d-flex flex-row flex-column-fluid page">
        <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
          <!-- begin:: Header -->
          <KTHeader v-if="!pageLoading"></KTHeader>
          <!-- end:: Header -->

          <!-- begin:: Content -->
          <div
                  id="kt_content"
                  class="content d-flex flex-column flex-column-fluid pb-0">
            <!-- begin:: Content Body -->

            <transition name="fade-in-up">
              <router-view v-if="getCompanyInfo"></router-view>
            </transition>

          </div>

          <KTFooter></KTFooter>
        </div>
      </div>
      <KTScrollTop></KTScrollTop>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import etcService from "@/core/services/models/etc.service"
import store from "@/core/services/store";
import {ACTION_SAVE_COMPANY_INFO, ACTION_SAVE_PROFILE_INFO} from "@/core/services/store/authentication.module";
import profileService from "@/core/services/models/profile.service";
import {getProfileInfo} from "../../core/services/jwt.service";

export default {
  name: "Layout",
  data(){
    return{
      companyName : "",
      pageLoading : true,
      spinnerClass: 'spinner-border text-red'
    }
  },
  components: {
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTScrollTop,
    Loader
  },
  beforeMount() {
    // show page loading
    if(this.isAuthenticated){
        if(getProfileInfo()){
            store.dispatch(ACTION_SAVE_PROFILE_INFO,getProfileInfo()).then( result =>{
                //console.log('profile info loaded');
            })
        }else {
            profileService.getPassengerProfile(s => {
                store.dispatch(ACTION_SAVE_PROFILE_INFO,s).then( result =>{
                    //console.log('profile info loaded');
                })
            })
        }
    }
    etcService.getCompanyInformation(companyInfo =>{
        store.dispatch(ACTION_SAVE_COMPANY_INFO,companyInfo).then( result =>{
            this.companyName = companyInfo.name;
            document.title = "Buy bus tickets - " + this.companyName;
            this.pageLoading = false;
        });
    });
    var canonicalElement = document.querySelector("link[rel='canonical']");
    canonicalElement.setAttribute('href', window.location.href);
    /*etcService.getCompanyWebsiteContent(companyWebContent =>{
      store.dispatch(ACTION_SAVE_COMPANY_WEB_CONTENT,companyWebContent).then( result =>{
        this.pageLoading = false;
      });
    });*/
    /*if(!getCompanyInfo()){
        etcService.getCompanyInformation(companyInfo =>{
            store.dispatch(ACTION_SAVE_COMPANY_INFO,companyInfo).then( result =>{
                document.title = companyInfo.name;
                this.pageLoading = false;
            });
        });
    }else {
        store.dispatch(ACTION_SAVE_COMPANY_INFO,getCompanyInfo()).then( result =>{
            document.title = getCompanyInfo().name;
            this.pageLoading = false;
        });

    }*/

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());

  },
  watch:{
    $route(to){
      document.title = to.name + " - " + this.companyName;
      //console.log(to);
    },
  },
  mounted() {

    // Simulate the delay page loading
    // setTimeout(() => {
    //   // Remove page loader after some time
    //   this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    // }, 2000);
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "getCompanyInfo",
      "pageTitle",
      "layoutConfig"
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      // return !/false/.test(this.layoutConfig("loader.type"));
      return false;
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return "media/logos/stars.png";
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return false;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    }
  }
};
</script>
