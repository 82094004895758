<template>
  <div>
    <!--begin::Header-->
    <div class="d-flex align-items-center p-8 rounded-top">
      <i class="far fa-user-circle fa-5x pr-3"></i>
      <div class="text-dark m-0 flex-column mr-3 font-size-h3">{{!userInfo  ? 'Guest': userInfo.name}}<br>
        <small class="text-dark-50 m-0 mr-3 font-size-h6">
          {{!userInfo ? '01&bigstar;&bigstar;&bigstar; &star;&star;&star;&star;&star;&star;': userInfo.mobile_number}}
        </small>
      </div>
      <div ></div>
      <!--<span-->
        <!--class="label label-light-danger label-lg font-weight-bold label-inline"-->
        <!--&gt;3 booked</span-->
      <!--&gt;-->
      <!--end::Text-->
    </div>
    <div class="separator separator-solid"></div>
    <!--end::Header-->

    <!--begin::Nav-->
    <div class="navi navi-spacer-x-0 pt-5" >
      <!--begin::Item-->
      <div class="navi-item px-8" style="background: transparent;" v-if="isLoggedIn">
        <div class="navi-link">
          <div class="navi-icon mr-2">
            <i class="fas fa-tachometer-alt text-aqua"></i>
          </div>
          <div class="navi-text">
            <div class="font-weight-bold">
              <router-link :to="'/profile/dashboard/'">My Profile Dashboard</router-link>
            </div>
            <div class="text-muted">
              My Profile Summary And Recently Booked Ticket List
            </div>
          </div>
        </div>
      </div>
      <!--end::Item-->

      <!--begin::Item-->
      <div class="navi-item px-8" style="background: transparent;" v-if="isLoggedIn">
        <div class="navi-link">
          <div class="navi-icon mr-2">
            <i class="fas fa-list-alt text-aqua"></i>
          </div>
          <div class="navi-text">
            <div class="font-weight-bold">
              <router-link :to="'/profile/tickets/'">My Tickets</router-link>
            </div>
            <div class="text-muted">
              My All Ticket List
            </div>
          </div>
        </div>
      </div>
      <!--end::Item-->

      <!--begin::Item-->
      <div class="navi-item px-8" style="background: transparent;" v-if="isLoggedIn">
        <div class="navi-link">
          <div class="navi-icon mr-2">
            <i class="fas fa-user-edit text-aqua"></i>
          </div>
          <div class="navi-text">
            <div class="font-weight-bold">
              <router-link :to="'/profile/edit/'">Profile Edit</router-link>
            </div>
            <div class="text-muted">
              Edit Profile Information
            </div>
          </div>
        </div>
      </div>
      <!--end::Item-->

      <!--begin::Footer-->
      <div class="navi-separator my-5" v-if="isLoggedIn"></div>
      <!--<div class="navi-footer  px-8 py-5 text-right">-->
      <div class="d-flex justify-content-end px-8 pb-5">
        <!--<a
          href="#"
          class="btn btn-light-primary font-weight-bold text-white"
          v-if="isLoggedIn"
          @click="onLogout"
          >Sign Out</a
        >-->
        <router-link v-if="isLoggedIn" :to="'/login'">
          <a class="btn btn-light-primary font-weight-bold text-white">Sign Out</a>
        </router-link>
        <router-link v-else :to="'/login'">
          <a class="btn btn-light-primary font-weight-bold text-white">Sign In</a>
        </router-link>

        <!--<a href="#" class="btn btn-clean font-weight-bold">Upgrade Plan</a>-->
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Nav-->
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {AUTH_LOGOUT} from "@/core/services/store/authentication.module";
import profileService from "@/core/services/models/profile.service";

export default {
  name: "KTDropdownUser",
  methods: {
      onLogout() {
          profileService.logOut(data =>{
              this.$store
                  .dispatch(AUTH_LOGOUT)
                  .then(() => this.$router.push({ name: "dashboard" }));
          })

      },
  },
  computed: {
      ...mapGetters(['userInfo','isLoggedIn'])
  }
};
</script>
