<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: Search -->
    <!--<b-dropdown-->
      <!--size="sm"-->
      <!--id="kt_quick_search_toggle"-->
      <!--variant="link"-->
      <!--toggle-class="topbar-item text-decoration-none"-->
      <!--no-caret-->
      <!--right-->
      <!--no-flip-->
    <!--&gt;-->
      <!--<template v-slot:button-content>-->
        <!--<div-->
          <!--class="btn btn-icon btn-hover-transparent-white btn-lg btn-dropdown mr-1"-->
        <!--&gt;-->
          <!--<span class="svg-icon svg-icon-xl">-->
            <!--<inline-svg src="media/svg/icons/General/Search.svg" />-->
          <!--</span>-->
        <!--</div>-->
      <!--</template>-->
      <!--<b-dropdown-text tag="div" class="min-w-md-350px">-->
        <!--<KTSearchDefault></KTSearchDefault>-->
      <!--</b-dropdown-text>-->
    <!--</b-dropdown>-->
    <!--end: Search -->

    <!--begin: Notifications -->
    <!--<b-dropdown-->
      <!--size="sm"-->
      <!--variant="link"-->
      <!--toggle-class="topbar-item text-decoration-none"-->
      <!--no-caret-->
      <!--right-->
      <!--no-flip-->
    <!--&gt;-->
      <!--<template v-slot:button-content>-->
        <!--<div-->
          <!--class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1 pulse pulse-primary"-->
        <!--&gt;-->
          <!--<span class="svg-icon svg-icon-xl">-->
            <!--<inline-svg src="media/svg/icons/Code/Compiling.svg" />-->
          <!--</span>-->
          <!--<span class="pulse-ring"></span>-->
        <!--</div>-->
      <!--</template>-->
      <!--<b-dropdown-text tag="div" class="min-w-md-350px">-->
        <!--<form>-->
          <!--<KTDropdownNotification></KTDropdownNotification>-->
        <!--</form>-->
      <!--</b-dropdown-text>-->
    <!--</b-dropdown>-->
    <!--end: Notifications -->

    <!--begin: Quick Actions -->
    <!--<b-dropdown-->
      <!--size="sm"-->
      <!--variant="link"-->
      <!--toggle-class="topbar-item text-decoration-none"-->
      <!--no-caret-->
      <!--right-->
      <!--no-flip-->
    <!--&gt;-->
      <!--<template v-slot:button-content>-->
        <!--<div-->
          <!--class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1"-->
        <!--&gt;-->
          <!--<span class="svg-icon svg-icon-xl">-->
            <!--<inline-svg src="media/svg/icons/Media/Equalizer.svg" />-->
          <!--</span>-->
        <!--</div>-->
      <!--</template>-->
      <!--<b-dropdown-text tag="div" class="min-w-md-350px">-->
        <!--<KTDropdownQuickAction></KTDropdownQuickAction>-->
      <!--</b-dropdown-text>-->
    <!--</b-dropdown>-->
    <!--end: Quick Actions -->

    <!--begin: Top Menu -->
    <scrollactive class="my-nav" v-on:itemchanged="onItemChanged" active-class="activeLink">
      <router-link to="/#home" class="scrollactive-item top-menu-link">Home</router-link>
      <router-link to="/#about_us" class="scrollactive-item top-menu-link">ABOUT US</router-link>
      <router-link to="/#contact_us" class="scrollactive-item top-menu-link">CONTACT US</router-link>
    </scrollactive>
    <!--end: Top Menu -->

    <!--begin: Top Passenger Link -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none ml-5"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div
          class="top-profile-link btn btn-icon rounded-0 btn-hover-transparent-white btn-dropdown text-black"
          v-bind:class="{ activeLink : isLoggedIn }"
        >
          <i class="far fa-user-circle fa-2x text-black mr-2"></i>Hi, {{userInfo && userInfo.name ? userInfo.name : 'Guest'}}
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <KTDropdownUser></KTDropdownUser>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Top Passenger Link -->

    <!--begin: Quick panel toggle -->
    <!--<KTQuickPanel v-if="!isLoggedIn"></KTQuickPanel>-->
    <!--end: Quick panel toggle -->

    <!--begin: Language bar -->
    <!--<div class="topbar-item">-->
      <!--<b-dropdown-->
        <!--size="sm"-->
        <!--variant="link"-->
        <!--toggle-class="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"-->
        <!--no-caret-->
        <!--right-->
        <!--no-flip-->
      <!--&gt;-->
        <!--<template v-slot:button-content>-->
          <!--<img-->
            <!--class="h-20px w-20px rounded-sm"-->
            <!--:src="languageFlag || getLanguageFlag"-->
            <!--alt=""-->
          <!--/>-->
        <!--</template>-->
        <!--<b-dropdown-text tag="div" class="min-w-md-175px">-->
          <!--<KTDropdownLanguage-->
            <!--v-on:language-changed="onLanguageChanged"-->
          <!--&gt;</KTDropdownLanguage>-->
        <!--</b-dropdown-text>-->
      <!--</b-dropdown>-->
    <!--</div>-->
    <!--end: Language bar -->

    <!--begin: User Bar -->
    <!--<KTQuickUser></KTQuickUser>-->
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
.top-menu-link{
  display:inline-block;
  width: auto !important;
  padding: 22px 15px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #444444;
  border-bottom: 4px solid transparent;
  &:hover {
    border-bottom: 4px solid #0865ff !important;
    color: #444444;
    background-color: rgba(255, 255, 255, 0.1);
  }
}
.activeLink {
  border-bottom: 4px solid #0865ff !important;
  color: #444444;
  background-color: rgba(255, 255, 255, 0.1);
}

.top-profile-link{
  width: auto !important;
  padding: 33px 15px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  border-bottom: 4px solid transparent;
  &:hover {
    border-bottom: 4px solid #0865ff !important;
  }
}
</style>

<script>
//import KTSearchDefault from "@/view/layout/extras/dropdown/SearchDefault.vue";
//import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
//import KTDropdownQuickAction from "@/view/layout/extras/dropdown/DropdownQuickAction.vue";
//import KTDropdownMyCart from "@/view/layout/extras/dropdown/DropdownMyCart.vue";
//import KTDropdownLanguage from "@/view/layout/extras/dropdown/DropdownLanguage.vue";
import KTDropdownUser from "@/view/layout/extras/dropdown/DropdownUser.vue";
//import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
//import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";
import i18nService from "@/core/services/i18n.service.js";
import {mapGetters} from 'vuex'

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages
    };
  },
  components: {
    // KTSearchDefault,
    // KTDropdownNotification,
    // KTDropdownQuickAction,
    // KTDropdownMyCart,
    // KTDropdownLanguage,
    KTDropdownUser
  },
  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    onItemChanged(event, currentItem, lastActiveItem) {
          // here you have access to everything you need regarding that event
    },
  },
  computed: {
      ...mapGetters([
         "isLoggedIn",
         "userInfo",
         "getCompanyInfo"
      ]),
    getLanguageFlag() {
      return this.onLanguageChanged();
    }
  }
};
</script>
