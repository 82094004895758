<template>
  <!-- begin:: Header -->
  <div
          id="kt_header"
          ref="kt_header"
          class="header"
          v-bind:class="headerClasses"
  >
    <div
            class="d-flex align-items-center justify-content-between desktop-topbar"
            v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-flex align-items-stretch mr-3 site-logo" v-if="!!getCompanyInfo">
        <div class="header-logo">
          <router-link to="/">
            <img
              :alt="getCompanyInfo.general_settings.site_name"
              :src="getCompanyInfo.general_settings.logo"
              class="logo-default" width="auto" height="50"
            />
            <img
              :alt="getCompanyInfo.general_settings.site_name"
              :src="getCompanyInfo.general_settings.logo"
              class="logo-sticky" width="auto" height="50"
            />
          </router-link>
        </div>
        <!-- begin:: Header Menu -->
        <div
                class="header-menu-wrapper header-menu-wrapper-left"
                ref="kt_header_menu_wrapper"
        >
          <div
                  v-if="headerMenuEnabled"
                  id="kt_header_menu"
                  ref="kt_header_menu"
                  class="header-menu header-menu-mobile"
                  v-bind:class="headerMenuClasses"
          >
            <!-- example static menu here -->
          </div>
        </div>
        <!-- end:: Header Menu -->
      </div>

      <KTTopbar></KTTopbar>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
  import { mapGetters } from "vuex";
  import KTTopbar from "@/view/layout/header/Topbar.vue";
  import KTLayoutHeader from "@/assets/js/layout/base/header.js";
  import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";

  export default {
    name: "KTHeader",
    components: {
      KTTopbar
    },
    mounted() {
      // Init Desktop & Mobile Headers
      KTLayoutHeader.init("kt_header", "kt_header_mobile");

      // Init Header Menu
      KTLayoutHeaderMenu.init(
              this.$refs["kt_header_menu"],
              this.$refs["kt_header_menu_wrapper"]
      );
    },
    computed: {
      ...mapGetters(["layoutConfig", "getClasses","getCompanyInfo"]),

      /**
       * Check if the header menu is enabled
       * @returns {boolean}
       */
      headerMenuEnabled() {
          //return !!this.layoutConfig("header.menu.self.display");
          return false;
      },

      /**
       * Get extra classes for header based on the options
       * @returns {null|*}
       */
      headerClasses() {
        const classes = this.getClasses("header");
        if (typeof classes !== "undefined") {
          return classes.join(" ");
        }
        return null;
      },

      /**
       * Get extra classes for header menu based on the options
       * @returns {null|*}
       */
      headerMenuClasses() {
        const classes = this.getClasses("header_menu");
        if (typeof classes !== "undefined") {
          return classes.join(" ");
        }
        return null;
      },

      /**
       * Check if header container is fluid
       */
      widthFluid() {
        return this.layoutConfig("header.self.width") === "fluid";
      }
    }
  };
</script>
